import * as React from 'react';

import { type TableState } from '@shared/components/report-table/components/types';
import { TableLoadingState } from '@shared/components/report-table/components/table-loading-state';
import { TableErrorMessage } from '@shared/components/report-table/components/table-error-state/components/table-message-error';

type TTableErrorStateProps = TableState & {
	invalidate(): Promise<void>;
};

export const TableErrorState: React.FC<TTableErrorStateProps> = ({ columns, invalidate }) => (
	<>
		<TableLoadingState columns={columns} />

		<TableErrorMessage invalidate={invalidate} />
	</>
);
