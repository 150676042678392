import { useTheme } from '@mui/material/styles';
import { type MenuProps } from '@mui/material';

export const useMenuPropsWithTheme = (): Partial<MenuProps> => {
	const theme = useTheme();

	return {
		anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
		PaperProps: {
			style: {
				backgroundColor: theme.palette.custom.light.highest,
				boxShadow: theme.palette.custom.dropdown.boxShadow,
				padding: theme.spacing(1.5, 1),
				borderRadius: theme.typography.pxToRem(12),
				minWidth: theme.typography.pxToRem(240),
			},
		},
		MenuListProps: {
			disablePadding: true,
		},
	};
};
