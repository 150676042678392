import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiListItemText from '@mui/material/ListItemText';
import MuiListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material';

import { interactiveMixin } from '@shared/components/interactive-mixin';

export const DropdownGhostInput = styled(InputBase)(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.custom.dark.highest,
})) as typeof InputBase;

export const StyledSelect = styled(Select)(({ theme }) => ({
	maxWidth: theme.typography.pxToRem(320),
	lineHeight: theme.typography.pxToRem(21.5625),

	'& .MuiSelect-select': {
		...interactiveMixin(theme),
	},

	'& .MuiSelect-icon': {
		top: `calc(50% - ${theme.spacing(1.25)})`, // because svg height is 20px (2.5em)
	},

	'&.custom-icon  .MuiSelect-select': {
		padding: theme.spacing(1.75, 2, 1.75, 4),
	},

	'&.custom-icon  .MuiSvgIcon-root': {
		fontSize: theme.typography.pxToRem(20),
		color: theme.palette.custom.dark.highest,
		right: 'unset',
		left: '7px',
	},
	'&.custom-icon .MuiSelect-iconOpen': {
		transform: 'rotate(0deg)',
	},
})) as unknown as typeof Select;

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
	borderRadius: theme.spacing(0.75),
	gap: theme.spacing(1),
	padding: theme.spacing(1),

	'&.Mui-selected:hover': {
		backgroundColor: theme.palette.custom.component.overlay,
	},
	'&.Mui-selected': {
		background: 'none',
	},
}));

export const Checkbox = styled(MuiCheckbox)(() => ({
	padding: 0,
}));

export const ListItemText = styled(MuiListItemText)(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.custom.dark.highest,
	display: 'flex',
	gap: theme.spacing(1),
	'& .MuiListItemText-primary': {
		flexGrow: 1,
	},
}));

export const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
	...theme.typography.subtitle2,
	backgroundColor: theme.palette.custom.light.highest,
	padding: theme.spacing(0, 1, 1.5, 1),
	color: theme.palette.custom.dark.high,
}));

export const ClearAllButton = styled(MuiMenuItem)(({ theme }) => ({
	...theme.typography.body1,
	padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(12)}`,
	backgroundColor: theme.palette.custom.light.highest,
	color: theme.palette.custom.dark.highest,
	width: `calc(100% - ${theme.spacing(3.5)})`,
	margin: theme.spacing(2.5, 'auto', 0),
	borderRadius: theme.typography.pxToRem(6),
	border: `1px solid ${theme.palette.custom.datepicker.border}`,
	textAlign: 'center',

	'&:hover': {
		border: `1px solid ${theme.palette.custom.dark.highest}`,
		color: theme.palette.custom.dark.highest,
	},

	'&:before': {
		content: '""',
		position: 'absolute',
		height: '0',
		top: theme.spacing(-1.5),
		left: theme.spacing(-3),
		right: theme.spacing(-3),
		borderTop: `1px solid ${theme.palette.custom.datepicker.border}`,
	},
}));
