import MuiInputAdornment from '@mui/material/InputAdornment';
import MuiList from '@mui/material/List';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemText from '@mui/material/ListItemText';
import MuiBox from '@mui/material/Box';
import MuiStack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material';

import { interactiveMixin } from '@shared/components/interactive-mixin';

export const CustomCalendarLabel = styled('label')(({ theme }) => ({
	...interactiveMixin(theme),
}));

export const InputAdornment = styled(MuiInputAdornment)(({ theme }) => ({
	color: theme.palette.custom.dark.highest,
	height: theme.spacing(2.5),
	width: theme.spacing(2.5),
}));

export const ShortcutList = styled(MuiList)(({ theme }) => ({
	width: theme.spacing(25),
	display: 'flex',
	flexDirection: 'column',
	padding: theme.spacing(1),
}));

export const ShortcutListItemButton = styled(MuiListItemButton)(({ theme }) => ({
	padding: theme.spacing(1),
	borderRadius: theme.spacing(0.75),

	'&.MuiListItemButton-root:hover, &.Mui-selected, &.Mui-selected:hover': {
		backgroundColor: theme.palette.custom.component.overlay,
	},
}));

export const ShortcutListItemText = styled(MuiListItemText)(({ theme }) => ({
	margin: 0,
	'& .MuiListItemText-primary': {
		...theme.typography.body1,
		color: theme.palette.custom.dark.highest,
	},
}));

export const ShortcutBox = styled(MuiBox)(({ theme }) => ({
	gridRow: 2,
	gridColumn: 1,
	borderRight: `1px solid ${theme.palette.custom.datepicker.border}`,
}));

export const HeaderTitleTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
}));

export const CalendarArrowIconButton = styled(IconButton)(({ theme }) => ({
	padding: 0,
	width: theme.spacing(2),
	height: theme.spacing(2),
	svg: {
		width: '100%',
	},
}));

export const CustomCalendarHeaderRoot = styled(MuiBox)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	padding: theme.spacing(2.25, 1.5),
	alignItems: 'center',
}));

export const CustomActionBarStack = styled(MuiStack)(({ theme }) => ({
	gap: theme.spacing(1),
	borderTop: `1px solid ${theme.palette.custom.datepicker.border}`,
	padding: theme.spacing(1.5),
	gridRow: 3,
	gridColumn: 'span 2',
}));

export const CalendarActionButton = styled(MuiButton)(({ theme }) => ({
	...theme.typography.body1,
	padding: theme.spacing(1.25, 1.5),
}));

export const CustomFieldHiddenStack = styled(MuiStack)(() => ({
	height: 0,
	visibility: 'hidden',
}));
