import { createContext, type Dispatch, type SetStateAction, useMemo, useState } from 'react';

import { type TDateShortcutType } from './types';
import { DEFAULT_DATE_SHORTCUT_ID } from './constant';

type TShortcutTypeValue = TDateShortcutType | null;
interface TDateShortcutTypeContextData {
	shortcutTypeValue: TShortcutTypeValue;
	setShortcutTypeValue: Dispatch<SetStateAction<TShortcutTypeValue>>;
}
export const DateShortcutTypeContext = createContext<TDateShortcutTypeContextData | undefined>(
	undefined,
);
export const DateShortcutTypeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [shortcutTypeValue, setShortcutTypeValue] = useState<TDateShortcutType | null>(
		DEFAULT_DATE_SHORTCUT_ID,
	);
	const providerValue = useMemo(
		() => ({ shortcutTypeValue, setShortcutTypeValue }),
		[shortcutTypeValue],
	);

	return (
		<DateShortcutTypeContext.Provider value={providerValue}>
			{children}
		</DateShortcutTypeContext.Provider>
	);
};
