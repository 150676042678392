import * as React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { addMonths, subMonths, format } from 'date-fns';
import { type PickersRangeCalendarHeaderProps } from '@mui/x-date-pickers-pro/PickersRangeCalendarHeader';

import { HeaderTitleTypography, CalendarArrowIconButton, CustomCalendarHeaderRoot } from './styles';

export const CustomCalendarHeader = (props: PickersRangeCalendarHeaderProps<Date>) => {
	const { currentMonth, onMonthChange, month, calendars, monthIndex } = props;

	const selectNextMonth = () => onMonthChange(addMonths(currentMonth, calendars), 'left');
	const selectPreviousMonth = () => onMonthChange(subMonths(currentMonth, calendars), 'right');

	return (
		<CustomCalendarHeaderRoot>
			<CalendarArrowIconButton
				onClick={selectPreviousMonth}
				sx={{ visibility: monthIndex === 0 ? undefined : 'hidden' }}
			>
				<ChevronLeft />
			</CalendarArrowIconButton>
			<HeaderTitleTypography>{format(month, 'MMMM yyyy')}</HeaderTitleTypography>
			<CalendarArrowIconButton
				onClick={selectNextMonth}
				sx={{ visibility: monthIndex === calendars - 1 ? undefined : 'hidden' }}
			>
				<ChevronRight />
			</CalendarArrowIconButton>
		</CustomCalendarHeaderRoot>
	);
};
