'use client';

import * as React from 'react';
import clsx from 'clsx';
import FormControl, { type FormControlProps } from '@mui/material/FormControl';
import { type SelectChangeEvent } from '@mui/material/Select';
import FilterListIcon from '@mui/icons-material/FilterList';

import { useMenuPropsWithTheme } from '../lib';
import { type TDropdownSelectProps } from '../types';

import IconSvg from './svg/icon.svg';
import CheckboxSvgIcon from './svg/checkbox.svg';
import CheckboxCheckedSvgIcon from './svg/checkbox-checked.svg';
import {
	Checkbox,
	ClearAllButton,
	DropdownGhostInput,
	ListItemText,
	ListSubheader,
	MenuItem,
	StyledSelect,
} from './styles';

export const DropdownSelect = <T extends string | number>({
	dropdownValue,
	onDropdownSelect,
	options,
	innerTitle,
	isMultiple = false,
	placeholder = '',
	resetButton = false,
	iconType = 'default',
	forcedPlaceholder,
	headerElement,
	...formControlProps
}: FormControlProps<'div', TDropdownSelectProps<T>>): React.ReactNode => {
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleChange = (event: SelectChangeEvent<typeof dropdownValue>) => {
		const { value } = event.target;
		const selectedVal = typeof value === 'string' ? (value.split(',') as T[]) : value;
		onDropdownSelect(selectedVal);
	};

	const resetValue = (e: React.MouseEvent<HTMLElement>) => {
		onDropdownSelect([]);
		e.stopPropagation();
	};

	const menuProps = useMenuPropsWithTheme();

	return (
		<FormControl {...formControlProps}>
			<StyledSelect
				native={false}
				notched={undefined} // https://github.com/mui/material-ui/issues/38512
				id="demo-multiple-checkbox"
				multiple={isMultiple}
				open={open}
				onClose={handleClose}
				onOpen={handleOpen}
				value={dropdownValue}
				onChange={handleChange}
				input={<DropdownGhostInput />}
				renderValue={(selected: T[]) => {
					if (forcedPlaceholder) {
						return forcedPlaceholder;
					}
					if (!selected.length) {
						return placeholder;
					}

					return selected
						.map((selectedVal) => options.find(({ value }) => value === selectedVal)?.label)
						.join(', ');
				}}
				displayEmpty
				className={clsx({ 'custom-icon': iconType !== 'default' })}
				IconComponent={iconType === 'default' ? IconSvg : FilterListIcon}
				MenuProps={menuProps}
			>
				{headerElement}
				{innerTitle && <ListSubheader disableSticky>{innerTitle}</ListSubheader>}
				{options.map(({ value, label, secondaryLabel }) => (
					<MenuItem key={value} value={value}>
						<Checkbox
							icon={<CheckboxSvgIcon />}
							checkedIcon={<CheckboxCheckedSvgIcon />}
							checked={dropdownValue.includes(value)}
						/>

						<ListItemText primary={label} secondary={secondaryLabel} />
					</MenuItem>
				))}

				{resetButton && (
					<ClearAllButton onClickCapture={resetValue}>
						<ListItemText primary="Deselect All" />
					</ClearAllButton>
				)}
			</StyledSelect>
		</FormControl>
	);
};
