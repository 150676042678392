import { type Theme } from '@mui/material';

export const interactiveMixin = (theme: Theme) => ({
	padding: theme.spacing(1.75, 2),
	borderRadius: theme.spacing(0.75),
	cursor: 'pointer',

	'&:hover': {
		backgroundColor: theme.palette.custom.interactive.hoverBg,
	},

	'&:active': {
		backgroundColor: theme.palette.custom.interactive.activeBg,
	},

	'&:focus': {
		backgroundColor: theme.palette.custom.interactive.hoverBg,
		boxShadow: `0 0 0 0.2rem #ffffff, 0 0 0 0.25rem ${theme.palette.custom.interactive.focusedBorderColor}`,
	},

	'&:focus-within': {
		backgroundColor: theme.palette.custom.interactive.hoverBg,
		boxShadow: `0 0 0 0.2rem #ffffff, 0 0 0 0.25rem ${theme.palette.custom.interactive.focusedBorderColor}`,
	},
});
