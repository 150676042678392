import { type Nullable } from '@shared/utils/nullish';

import { identity } from '../functional/identity';

import { assertEnv } from './assert-env';

type TGetEnvCast<T, R> = (value: NonNullable<T>) => NonNullable<R>;

export const getEnv = <T, R = T>(
	value: Nullable<T>,
	castValue: TGetEnvCast<T, R> = identity as TGetEnvCast<T, R>,
): NonNullable<R> => {
	assertEnv(value);

	return castValue(value);
};
