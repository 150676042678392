import { Button, styled } from '@mui/material';

import { MessageOverlay, MessageOverlayDescription } from '@shared/components/report-table/styles';

export const MessageErrorOverlay = styled(MessageOverlay)(({ theme }) => ({
	'& > *:first-child': {
		marginBottom: theme.typography.pxToRem(12),
	},
}));

export const MessageErrorOverlayDescription = styled(MessageOverlayDescription)(({ theme }) => ({
	maxWidth: theme.typography.pxToRem(400),
	textAlign: 'center',
}));

export const ReloadPageButton = styled(Button)(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.primary.contrastText,

	borderRadius: theme.typography.pxToRem(6),
	background: theme.palette.primary.main,
	padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(12)}`,

	marginTop: theme.typography.pxToRem(32),
}));

export const WarningIconContainer = styled('div')(({ theme }) => ({
	marginBottom: theme.typography.pxToRem(16),
}));
