import { isValid, parseISO } from 'date-fns';

export const LA_TIME_ZONE = 'America/Los_Angeles';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const createTodayDate = () => new Date();

export const isValidDate = (date: string) => isValid(parseISO(date));

export const isValidDateRange = (dateRange: (string | null)[]) =>
	dateRange.every((date) => isValidDate(String(date)));
