import type React from 'react';
import { type Theme } from '@mui/material';
import { type DateRangePickerSlotProps } from '@mui/x-date-pickers-pro/DateRangePicker';

import { shortcutItems } from './shortcut-items';

export const getSlotProps = (
	size: 'small' | 'medium',
	startAdornment: React.ReactNode,
): DateRangePickerSlotProps<Date, false> => ({
	desktopPaper: {
		// usage of these classes is not documented in the mui library
		// I figured it out on my own
		sx: (theme: Theme) => ({
			'& .MuiDateRangeCalendar-root': {
				padding: theme.spacing(1.5),
			},
			'& .MuiDateRangeCalendar-monthContainer:not(:last-of-type)': {
				borderRight: 'none',
			},
			'& .MuiDayCalendar-header': {
				padding: theme.spacing(0, 1.5),
				margin: theme.spacing(0.5, 0),
				gap: theme.spacing(0.25),
			},
			'& .MuiDayCalendar-weekDayLabel': {
				flexGrow: 1,
				...theme.typography.weekDayLabel,
				color: theme.palette.custom.component.placeholder,
			},

			// read the link for further
			// https://mui.com/x/api/date-pickers/date-range-picker-day/#classes
			'& .MuiDateRangePickerDay-root:last-of-type': {
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,
			},
			'& .MuiDateRangePickerDay-root:first-of-type': {
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
			},
			'& .MuiDateRangePickerDay-root': {
				'& .MuiDateRangePickerDay-day': {
					...theme.typography.weekDayButton,
					borderRadius: theme.spacing(0.75),
				},
				'&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
					backgroundColor: theme.palette.background.default,
				},
				'& .MuiDateRangePickerDay-day.Mui-selected': {
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.secondary.contrastText,
					fontWeight: 500,
				},
				'& .MuiDateRangePickerDay-rangeIntervalDayPreview': {
					backgroundColor: theme.palette.background.default,
					borderColor: 'transparent',
				},
				'& .MuiDateRangePickerDay-rangeIntervalDayPreviewEnd': {
					borderTopRightRadius: theme.spacing(0.75),
					borderBottomRightRadius: theme.spacing(0.75),
				},
				'& .MuiDateRangePickerDay-rangeIntervalDayPreviewStart': {
					borderTopLeftRadius: theme.spacing(0.75),
					borderBottomLeftRadius: theme.spacing(0.75),
				},
				'& .MuiDateRangePickerDay-day.MuiPickersDay-today': {
					border: `1px solid ${theme.palette.custom.datepicker.border}`,
				},
			},

			backgroundColor: theme.palette.custom.light.highest,
		}),
	},
	shortcuts: {
		items: shortcutItems,
	},
	textField: () => ({
		variant: 'standard',
		size,
		InputProps: {
			sx: {
				'&.MuiInputBase-root': {
					cursor: 'pointer',
					userSelect: 'none',
				},
				'& .MuiInputBase-input': {
					padding: 0,
					minWidth: '190px',
				},
			},
			readOnly: true,
			disableUnderline: true,
			startAdornment,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
				e.preventDefault();
			},
		},
	}),
	actionBar: {
		actions: ['accept', 'cancel'],
	},
});
