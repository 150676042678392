import TablePagination from '@mui/material/TablePagination';
import * as React from 'react';

import { type TReportPaginationProps } from '@shared/components/report-pagination/types';

import { ReportPaginationContainer, TotalCountTypography } from './styles';

export const ReportPagination: React.FC<TReportPaginationProps> = ({
	totalCount,
	rowsPerPage,
	page,
	onPageChange,
	onRowsPerPageChange,
}) => (
	<ReportPaginationContainer>
		<TotalCountTypography>{totalCount} results</TotalCountTypography>

		<TablePagination
			rowsPerPageOptions={[10, 30]}
			component="div"
			count={totalCount}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={onPageChange}
			onRowsPerPageChange={onRowsPerPageChange}
		/>
	</ReportPaginationContainer>
);
