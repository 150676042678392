import { Close, Search } from '@mui/icons-material';
import { type ChangeEvent, useEffect, useRef } from 'react';

import {
	SearchFieldContainer,
	SearchFieldInput,
	SearchFieldResetButton,
} from '@shared/components/search-input/styles';

import { type TSearchInputProps } from './types';

export const SearchField: React.FC<TSearchInputProps> = ({
	size = 'md',
	searchString,
	setSearchString,
	placeholder,
	onResetSearchString,
}) => {
	const fieldRef = useRef<HTMLInputElement>(null);

	// Forced focus for DropdownSelect case; probably could be optimized in favour of mui Autocomplete
	useEffect(() => {
		fieldRef.current?.focus();
	});

	const isSmall = size === 'sm';

	return (
		<SearchFieldContainer isRegular={!isSmall}>
			<Search fontSize={isSmall ? 'small' : 'medium'} />

			<SearchFieldInput
				type="text"
				ref={fieldRef}
				isRegular={!isSmall}
				value={searchString}
				onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchString(event.target.value)}
				// in DropdownSelect every keyDown triggers focus at matching option
				// here we prevent it; Anyway must be refactored later in favour of Mui Autocomplete
				onKeyDown={(event) => event.stopPropagation()}
				placeholder={placeholder}
			/>

			{searchString.length > 0 && (
				<SearchFieldResetButton onClick={onResetSearchString}>
					<Close />
				</SearchFieldResetButton>
			)}
		</SearchFieldContainer>
	);
};
export { type TSearchInputProps } from './types';
