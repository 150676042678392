import Stack from '@mui/material/Stack';
import {
	SingleInputDateRangeField,
	type SingleInputDateRangeFieldProps,
	type FieldType,
} from '@mui/x-date-pickers-pro';
import { forwardRef, useEffect, useRef, useState } from 'react';

import CalendarSvgIcon from './svg/calendar.svg';
import { InputAdornment, CustomFieldHiddenStack } from './styles';

export const CustomSingleInputDateRangeField: React.FC<
	SingleInputDateRangeFieldProps<Date, false>
> & { fieldType?: FieldType } = forwardRef<HTMLDivElement, SingleInputDateRangeFieldProps<Date>>(
	(props, ref) => {
		const [inputValue, setInputValue] = useState<string>();
		const inputRef = useRef<HTMLInputElement>();
		useEffect(() => {
			if (inputRef.current?.value) {
				setInputValue(inputRef.current?.value);
			}
		});
		return (
			<Stack>
				<SingleInputDateRangeField {...props} ref={ref} inputRef={inputRef} />
				<CustomFieldHiddenStack direction="row">
					<InputAdornment position="start">
						<CalendarSvgIcon />
					</InputAdornment>
					{inputValue}
				</CustomFieldHiddenStack>
			</Stack>
		);
	},
);
CustomSingleInputDateRangeField.displayName = 'CustomSingleInputDateRangeField';
CustomSingleInputDateRangeField.fieldType = 'single-input';
