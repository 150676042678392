export const AUTH_PAGE_PATH = '/login/';

export const DASHBOARD_PAGE_PATH = '/';
export const OVERVIEW_REPORT_PATH = '/report/overview/';
export const DETAILED_REPORT_PATH = '/report/detailed/';
export const PROFILE_PATH = '/profile/';
export const REQUEST_RESET_PATH = '/request-reset/';
export const RESET_PASSWORD_PATH = '/reset-password/';
export const UPDATE_PASSWORD_PATH = '/update-password/';
export const NOT_FOUND_PATH = '/404/';
