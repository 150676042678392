'use client';

import { styled } from '@mui/material';

export const ReportFiltersContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexFlow: 'row nowrap',
	justifyContent: 'flex-start',
	alignItems: 'center',
	width: '100%',
	background: theme.palette.custom.component.tableRow,
	padding: theme.typography.pxToRem(8),

	'& > *:not(:last-child)': {
		marginRight: theme.typography.pxToRem(10),
	},
}));

export const DateRangeFilterContainer = styled('div')(({ theme }) => ({
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: theme.typography.pxToRem(48),
}));
