import TableCell from '@mui/material/TableCell';
import * as React from 'react';
import { Skeleton } from '@mui/material';

import { times } from '@shared/utils/functional/times';
import { getBaseColumnProps } from '@shared/components/report-table/utils/get-base-column-props';
import { type TableState } from '@shared/components/report-table/components/types';
import { ReportTableEmptyRowData } from '@shared/components/report-table/styles';

type TTableLoadingStateProps = TableState;

const loadingRowsCount = 7;

export const TableLoadingState: React.FC<TTableLoadingStateProps> = ({ columns }) => (
	<>
		{times(loadingRowsCount, (index) => (
			<ReportTableEmptyRowData tabIndex={-1} key={index}>
				{columns.map((column) => (
					<TableCell key={column.id} {...getBaseColumnProps(column)}>
						<Skeleton />
					</TableCell>
				))}
			</ReportTableEmptyRowData>
		))}
	</>
);
