import { isNullish } from '@shared/utils/nullish';

import { getStorage } from './get-storage';

const safeParseJSON = <S>(value: string): S | string => {
	try {
		return JSON.parse(value) as S;
	} catch (error) {
		return value;
	}
};

export const loadStorageState = <S>(key: string, persistent?: boolean): S | string | undefined => {
	try {
		const state = getStorage(persistent).getItem(key);

		if (isNullish(state)) {
			return undefined;
		}

		return safeParseJSON<S>(state);
	} catch (error) {
		return undefined;
	}
};
