import type React from 'react';

import { RefundBadge } from '@shared/components/table-refund-badge/style';

import { type TRefundedCellProps } from './types';
import { BeforeRefundTypography, EarningsRefundTypography, RefundedCellContainer } from './styles';

export const RefundedCell: React.FC<TRefundedCellProps> = ({
	beforeRefundAmount,
	afterRefundAmount,
	isEarningsRefund,
}) => (
	<RefundedCellContainer>
		<RefundBadge>Refunded</RefundBadge>

		{!isEarningsRefund && <BeforeRefundTypography>{beforeRefundAmount}</BeforeRefundTypography>}

		{isEarningsRefund ? (
			<EarningsRefundTypography>{afterRefundAmount}</EarningsRefundTypography>
		) : (
			<span>{afterRefundAmount}</span>
		)}
	</RefundedCellContainer>
);
