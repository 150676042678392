import { loadStorageState, hasStorageState } from '@shared/utils/storage';
import { type TLoginTokenInfo } from '@shared/api/auth/types';
import { loginResponseTokensSchema } from '@shared/api/auth/schemas';

import { STORAGE_TOKEN_KEY } from '../constants';

export const getApiToken = () => {
	const persistent = hasStorageState(STORAGE_TOKEN_KEY, true);
	const maybeToken = loadStorageState<TLoginTokenInfo>(STORAGE_TOKEN_KEY, persistent);
	try {
		return loginResponseTokensSchema.parse(maybeToken);
	} catch (error) {
		return undefined;
	}
};
