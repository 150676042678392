import { styled, Typography } from '@mui/material';

export const RefundedCellContainer = styled('div')(({ theme }) => ({
	display: 'inline-flex',
	flexFlow: 'row nowrap',
	justifyContent: 'center',
	alignItems: 'center',

	'& > :nth-child(1)': {
		marginRight: theme.typography.pxToRem(16),
	},

	'& > :nth-child(2)': {
		marginRight: theme.typography.pxToRem(8),
	},

	'& > :last-child': {
		marginRight: 0,
	},
}));

export const BeforeRefundTypography = styled(Typography)(({ theme }) => ({
	textDecoration: 'line-through',
	color: theme.palette.custom.component.placeholder,
}));

export const EarningsRefundTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.custom.refundBadge.color,
}));
