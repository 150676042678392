import { type TReportSortingOrder } from '@shared/components/report-table/types';

export const mapSortingOrderToBackend = (order: TReportSortingOrder) => {
	switch (order) {
		case 'asc':
			return 'ASC';

		case 'desc':
		default:
			return 'DESC';
	}
};
