import { type ChangeEvent, useState } from 'react';

type TUseTablePaginationArgs = {
	pageRowsCount: number;
};

export const useTablePagination = ({ pageRowsCount }: TUseTablePaginationArgs) => {
	const [page, setPage] = useState(0);

	const [rowsPerPage, setRowsPerPage] = useState(pageRowsCount);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(Number(event.target.value));
		setPage(0);
	};

	return {
		page,
		setPage,
		rowsPerPage,
		handleChangePage,
		handleChangeRowsPerPage,
	};
};
