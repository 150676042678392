import { getEnv } from '@shared/utils/env';

export const BASE_URL = getEnv<string, string>(process.env.NEXT_PUBLIC_API_BASE_URL);

export const STORAGE_TOKEN_KEY = 'tokenInfo';

export const UNAUTHORIZED_STATUS_CODE = 401;

export const ACCOUNT_BLOCKED_STATUS_CODE = 403;

export const NOT_FOUND_STATUS_CODE = 404;

export const UNAUTHORIZED_ERROR_CODE = 'UNAUTHORIZED';
