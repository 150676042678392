import { Button, styled } from '@mui/material';

export const SortingButtonContainer = styled(Button)(() => ({
	margin: 0,
	padding: 0,
	border: 'none',
	boxSizing: 'border-box',
	background: 'transparent',
	minWidth: 'unset',

	'&:hover': {
		background: 'transparent',
	},
}));
