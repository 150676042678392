import { format } from 'date-fns';

const dateFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";

const pstOffsetFormat = '-07:00';

export const mapDateToBackend = (dateString: string | null) => {
	const normalizedDateString = dateString ?? new Date().toISOString();

	const localDate = new Date(normalizedDateString);
	localDate.setHours(0, 0, 0, 0);

	const offset = format(localDate, 'xxx');

	const formattedLocalDate = format(new Date(localDate), dateFormat);

	return formattedLocalDate.replace(offset, pstOffsetFormat);
};
