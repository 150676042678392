import { useCallback, useState } from 'react';

import { type TReportSorting } from '@shared/components/report-table/types';

export type TUseTableSortingArgs<T extends string> = {
	defaultSortingOrder: TReportSorting<T>;
};

export const useTableSorting = <T extends string>({
	defaultSortingOrder,
}: TUseTableSortingArgs<T>) => {
	const [sorting, setSorting] = useState<TReportSorting<T>>({
		...defaultSortingOrder,
		date: 'desc',
	});

	const handleSortingChange = useCallback(
		(columnId: T) => {
			switch (sorting[columnId]) {
				case 'asc': {
					setSorting({ ...defaultSortingOrder, [columnId]: 'none' });
					break;
				}

				case 'desc': {
					setSorting({ ...defaultSortingOrder, [columnId]: 'asc' });
					break;
				}

				case 'none': {
					setSorting({ ...defaultSortingOrder, [columnId]: 'desc' });
					break;
				}

				// no default
			}
		},
		[defaultSortingOrder, sorting],
	);

	return { sorting, handleSortingChange };
};
