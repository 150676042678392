import * as React from 'react';
import { type PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

import { CustomActionBarStack, CalendarActionButton } from './styles';

export const CustomActionBar: React.FC<PickersActionBarProps> = ({
	onAccept,
	onCancel,
	actions,
}) => {
	if (!actions || actions.length === 0) {
		return null;
	}

	const menuItems = actions?.map((actionType) => {
		switch (actionType) {
			case 'cancel':
				return (
					<CalendarActionButton
						sx={(theme) => ({
							backgroundColor: theme.palette.custom.component.overlay,
							color: theme.palette.custom.dark.highest,
							'&:hover': {
								backgroundColor: theme.palette.custom.light.higher,
								color: theme.palette.custom.dark.highest,
							},
						})}
						size="medium"
						onClick={() => {
							onCancel();
						}}
						key={actionType}
					>
						Cancel
					</CalendarActionButton>
				);
			case 'accept':
				return (
					<CalendarActionButton
						sx={(theme) => ({
							backgroundColor: theme.palette.custom.dark.highest,
							color: theme.palette.custom.light.highest,
							'&:hover': {
								backgroundColor: theme.palette.custom.dark.high,
								color: theme.palette.custom.light.highest,
							},
						})}
						size="medium"
						onClick={() => {
							onAccept();
						}}
						key={actionType}
					>
						Apply
					</CalendarActionButton>
				);
			case 'clear':
			case 'today':
			default:
				return null;
		}
	});

	return <CustomActionBarStack direction="row-reverse">{menuItems}</CustomActionBarStack>;
};
