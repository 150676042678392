import { styled } from '@mui/material';

export const SearchFieldContainer = styled('label')<{ isRegular?: boolean }>(
	({ theme, isRegular }) => ({
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'center',
		alignItems: 'center',
		padding: isRegular ? theme.spacing(1.75, 2) : theme.spacing(1.75, 0),
		width: '100%',
		borderRadius: theme.typography.pxToRem(6),

		'&:focus-within': {
			...(isRegular ? { backgroundColor: theme.palette.custom.input.focusedBg } : {}),
		},

		'& > *:not(:last-child)': {
			marginRight: theme.typography.pxToRem(8),
		},
		'& > svg': {
			color: theme.palette.custom.dark.high,
		},
	}),
);

export const SearchFieldInput = styled('input')<{ isRegular?: boolean }>(
	({ theme, isRegular }) => ({
		...theme.typography.body1,
		color: theme.palette.primary.main,
		border: 'none',
		outline: 'none',
		margin: 0,
		padding: 0,
		backgroundColor: 'transparent',
		flex: '1 1 100%',

		'&::placeholder': {
			...theme.typography.body1,
			color: isRegular ? theme.palette.primary.main : theme.palette.custom.dark.high,
		},
	}),
);

export const SearchFieldResetButton = styled('button')(({ theme }) => ({
	width: theme.typography.pxToRem(24),
	height: theme.typography.pxToRem(24),
	border: 'none',
	outline: 'none',
	margin: 0,
	padding: 0,
	backgroundColor: 'transparent',
	cursor: 'pointer',
}));
