import { type TReportSortingOrder } from '@shared/components/report-table/types';

import { SortingButtonContainer } from './styles';

type TSortingIconProps = {
	sortingOrder: TReportSortingOrder;
	onClick(): void;
};

export const SortingButton: React.FC<TSortingIconProps> = ({ sortingOrder, onClick }) => {
	const ascArrowColor = sortingOrder === 'asc' ? '#000' : '#7A757F';

	const descArrowColor = sortingOrder === 'desc' ? '#000' : '#7A757F';

	return (
		<SortingButtonContainer onClick={onClick}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
			>
				<path
					d="M4.69998 3V13M4.69998 13L7.4 10.3M4.69998 13L2 10.3"
					stroke={descArrowColor}
					strokeWidth="1.5"
					strokeLinecap="square"
					strokeLinejoin="round"
				/>

				<path
					d="M11.3001 13V3M11.3001 3L8.6001 5.7M11.3001 3L14.0001 5.7"
					stroke={ascArrowColor}
					strokeWidth="1.5"
					strokeLinecap="square"
					strokeLinejoin="round"
				/>
			</svg>
		</SortingButtonContainer>
	);
};
