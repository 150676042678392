import { type Dispatch, type SetStateAction, useEffect, useRef } from 'react';

import { type TOptions } from './types';

export const useSetOptionsOnce = (
	options: TOptions,
	setOptions: Dispatch<SetStateAction<TOptions>>,
) => {
	const optionsAlreadySet = useRef(false);

	useEffect(() => {
		if (optionsAlreadySet.current) {
			return;
		}

		if (options) {
			setOptions(options);

			optionsAlreadySet.current = true;
		}
	}, [setOptions, options]);
};
