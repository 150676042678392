import axios, { type AxiosError } from 'axios';

import {
	ACCOUNT_BLOCKED_STATUS_CODE,
	BASE_URL,
	UNAUTHORIZED_STATUS_CODE,
} from '@shared/api/constants';
import { clearApiToken } from '@shared/api/utils/clear-api-token';
import { AUTH_PAGE_PATH, RESET_PASSWORD_PATH, UPDATE_PASSWORD_PATH } from '@shared/constants';

export const axiosInstance = axios.create({
	baseURL: BASE_URL,
	timeout: 60_000,
	withCredentials: true,
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => {
		const status = error?.response?.status;

		if (
			(status === UNAUTHORIZED_STATUS_CODE || status === ACCOUNT_BLOCKED_STATUS_CODE) &&
			!RESET_PASSWORD_PATH.includes(window.location.pathname) &&
			!UPDATE_PASSWORD_PATH.includes(window.location.pathname)
		) {
			clearApiToken();
			window.location.assign(AUTH_PAGE_PATH);
		}

		return Promise.reject(error);
	},
);
