import { nonNullish } from '@shared/utils/nullish';

import { getStorage } from './get-storage';

export const hasStorageState = (key: string, persistent?: boolean): boolean => {
	try {
		return nonNullish(getStorage(persistent).getItem(key));
	} catch (error) {
		return false;
	}
};
