import { styled } from '@mui/material';

export const RefundBadge = styled('div')(({ theme }) => ({
	display: 'inline-flex',
	flexFlow: 'row nowrap',
	justifyContent: 'center',
	alignItems: 'center',
	padding: `${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(4)}`,
	backgroundColor: theme.palette.custom.refundBadge.bg,
	border: `1px solid ${theme.palette.custom.refundBadge.borderColor}`,
	color: theme.palette.custom.refundBadge.color,
	borderRadius: theme.typography.pxToRem(4),
	...theme.typography.badge,
}));
