import { subDays, differenceInDays, formatISO } from 'date-fns';

import { type TDateRangeValue } from './types';

export const getPreviousDateRange = (dateRange: TDateRangeValue): TDateRangeValue => {
	const startDate = dateRange[0] ?? new Date().toISOString();
	const endDate = dateRange[1] ?? new Date().toISOString();

	const rangeDuration = differenceInDays(endDate, startDate);

	const newEndDate = subDays(startDate, 1);
	const newStartDate = subDays(newEndDate, rangeDuration);
	return [formatISO(newStartDate), formatISO(newEndDate)];
};
