import { MessageEmptyOverlay } from '@shared/components/report-table/components/table-empty-state/styles';
import {
	MessageOverlayDescription,
	MessageOverlayTitle,
} from '@shared/components/report-table/styles';

export const TableMessageEmpty = () => (
	<MessageEmptyOverlay>
		<MessageOverlayTitle>No data available within chosen time frame</MessageOverlayTitle>

		<MessageOverlayDescription>
			Adjust your filters or try different dates
		</MessageOverlayDescription>
	</MessageEmptyOverlay>
);
