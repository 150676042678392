import { type DateRange, type PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import {
	startOfMonth,
	startOfWeek,
	startOfYear,
	startOfDay,
	subDays,
	subMonths,
	subYears,
	startOfQuarter,
	endOfDay,
} from 'date-fns';

import { createTodayDate } from '@shared/utils/date';

import { type TDateShortcutType } from './types';

type TPickerShortcutsItem = PickersShortcutsItem<DateRange<Date>> & { id: TDateShortcutType };

export const shortcutItems: TPickerShortcutsItem[] = [
	{
		id: 'last_7_days',
		label: 'Last 7 days',
		getValue: () => {
			const today = createTodayDate();
			return [startOfDay(subDays(today, 6)), endOfDay(today)];
		},
	},

	{
		id: 'last_14_days',
		label: 'Last 14 days',
		getValue: () => {
			const today = createTodayDate();
			return [startOfDay(subDays(today, 13)), endOfDay(today)];
		},
	},
	{
		id: 'last_30_days',
		label: 'Last 30 days',
		getValue: () => {
			const today = createTodayDate();
			return [startOfDay(subDays(today, 29)), endOfDay(today)];
		},
	},

	{
		id: 'last_3_months',
		label: 'Last 3 months',
		getValue: () => {
			const today = createTodayDate();
			return [startOfDay(subMonths(today, 3)), endOfDay(today)];
		},
	},

	{
		id: 'last_12_months',
		label: 'Last 12 months',
		getValue: () => {
			const today = createTodayDate();
			return [startOfDay(subYears(today, 1)), endOfDay(today)];
		},
	},
	{
		id: 'week_to_date',
		label: 'Week to date',
		getValue: () => {
			const today = createTodayDate();
			return [startOfWeek(today, { weekStartsOn: 0 }), endOfDay(today)];
		},
	},
	{
		id: 'month_to_date',
		label: 'Month to date',
		getValue: () => {
			const today = createTodayDate();
			return [startOfMonth(today), endOfDay(today)];
		},
	},
	{
		id: 'quarter_to_date',
		label: 'Quarter to date',
		getValue: () => {
			const today = createTodayDate();
			return [startOfQuarter(today), endOfDay(today)];
		},
	},
	{
		id: 'year_to_date',
		label: 'Year to date',
		getValue: () => {
			const today = createTodayDate();
			return [startOfYear(today), endOfDay(today)];
		},
	},
];
