import * as React from 'react';

import { type TableState } from '@shared/components/report-table/components/types';
import { TableLoadingState } from '@shared/components/report-table/components/table-loading-state';
import { TableMessageEmpty } from '@shared/components/report-table/components/table-empty-state/components/table-message-empty';

type TTableEmptyStateProps = TableState;

export const TableEmptyState: React.FC<TTableEmptyStateProps> = ({ columns }) => (
	<>
		<TableLoadingState columns={columns} />

		<TableMessageEmpty />
	</>
);
