'use client';

import React, { useContext, useRef } from 'react';
import {
	type DateRange,
	DateRangePicker,
	type DateRangeValidationError,
	type PickerChangeHandlerContext,
	type PickerValidDate,
	type RangePosition,
} from '@mui/x-date-pickers-pro';
import { format, isValid, parseISO } from 'date-fns';

import { DATE_FORMAT, isValidDate, isValidDateRange, LA_TIME_ZONE } from '@shared/utils/date';

import {
	DateShortcutTypeContext,
	DateShortcutTypeProvider,
	getSlotProps,
	type TDateRangeValue,
	type TDateShortcutType,
} from '../lib';

import { CustomSingleInputDateRangeField } from './custom-single-input-date-range-field';
import { CustomCalendarHeader } from './custom-calendar-header';
import { CustomRangeShortcuts } from './custom-range-shortcuts';
import { CustomActionBar } from './custom-action-bar';
import { CustomCalendarLabel, InputAdornment } from './styles';
import CalendarSvgIcon from './svg/calendar.svg';

const adornment = (
	<InputAdornment position="start">
		<CalendarSvgIcon />
	</InputAdornment>
);

type TProps = {
	value: Readonly<TDateRangeValue>;
	setValue: (dateRange: TDateRangeValue) => void;
	minDate?: PickerValidDate;
	maxDate?: PickerValidDate;
	disableFuture?: boolean;
	label?: string;
	size?: 'small' | 'medium';
	sx?: Parameters<typeof DateRangePicker>[number]['sx'];
};

const CustomDateRangePickerUI: React.FC<TProps> = ({
	value,
	setValue,
	minDate,
	maxDate,
	disableFuture = true,
	label,
	size = 'small',
	sx,
}) => {
	const positionRange = useRef<RangePosition | undefined>('start');

	const dateShortcutTypeContext = useContext(DateShortcutTypeContext);

	const handleChange = (
		newValue: DateRange<PickerValidDate>,
		context: PickerChangeHandlerContext<DateRangeValidationError>,
	) => {
		const newFirstDate =
			newValue[0] && isValid(newValue[0]) ? format(newValue[0], DATE_FORMAT) : null;

		const newLastDate =
			newValue[1] && isValid(newValue[1]) ? format(newValue[1], DATE_FORMAT) : null;

		if (context?.shortcut?.id) {
			dateShortcutTypeContext?.setShortcutTypeValue(context.shortcut.id as TDateShortcutType);
			setValue([newFirstDate, newLastDate]);
			return;
		}

		if (value[0] === newFirstDate && value[1] === newLastDate) {
			return;
		}

		dateShortcutTypeContext?.setShortcutTypeValue(null);

		if (positionRange.current === 'start' && isValidDate(String(newFirstDate))) {
			setValue([newFirstDate, null]);
			positionRange.current = 'end';
		} else if (isValidDateRange([newFirstDate, newLastDate])) {
			setValue([newFirstDate, newLastDate]);
			positionRange.current = 'start';
		}
	};

	const slotProps = getSlotProps(size, adornment);

	return (
		<CustomCalendarLabel>
			<DateRangePicker
				sx={{
					...sx,
				}}
				slots={{
					calendarHeader: CustomCalendarHeader,
					field: CustomSingleInputDateRangeField,
					shortcuts: CustomRangeShortcuts,
					actionBar: CustomActionBar,
				}}
				format="LLL dd, yyyy"
				slotProps={slotProps}
				rangePosition={positionRange.current}
				value={
					[
						value[0] === null ? null : parseISO(value[0]),
						value[1] === null ? null : parseISO(value[1]),
					] as DateRange<PickerValidDate>
				}
				onChange={(selectedDates, context) => {
					handleChange(selectedDates, context);
				}}
				minDate={minDate}
				maxDate={maxDate}
				disableFuture={disableFuture}
				label={label}
				timezone={LA_TIME_ZONE}
				onOpen={() => {
					positionRange.current = 'start';
				}}
				onClose={() => {
					if (value[1] === null && positionRange.current === 'end') {
						setValue([value[0], value[0]]);
						positionRange.current = 'start';
					}
				}}
			/>
		</CustomCalendarLabel>
	);
};

export const CustomDateRangePicker: React.FC<TProps> = (props) => (
	<DateShortcutTypeProvider>
		<CustomDateRangePickerUI {...props} />
	</DateShortcutTypeProvider>
);
