import { styled } from '@mui/material';

export const ReportPaginationContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexFlow: 'row nowrap',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: theme.typography.pxToRem(8),
}));

export const TotalCountTypography = styled('span')(({ theme }) => ({
	padding: theme.typography.pxToRem(16),
	color: theme.palette.custom.component.placeholder,
}));
