import { useEffect, useRef, useState } from 'react';

export const useDebouncedValue = <T>(defaultValue: T, timeout: number) => {
	const [value, setValue] = useState<T>(defaultValue);

	const [debouncedValue, setDebouncedValue] = useState<T>(defaultValue);

	const timerId = useRef<number>(0);

	useEffect(() => {
		timerId.current = window.setTimeout(() => {
			setDebouncedValue(value);
		}, timeout);

		return () => window.clearTimeout(timerId.current);
	}, [timeout, value]);

	return {
		value,
		setValue,
		debouncedValue,
	};
};
