import * as React from 'react';
import { type PickersShortcutsProps } from '@mui/x-date-pickers/PickersShortcuts';
import { type DateRange } from '@mui/x-date-pickers-pro/models';
import { useContext } from 'react';

import { DateShortcutTypeContext, type TDateShortcutType } from '../lib';

import { ShortcutList, ShortcutListItemButton, ShortcutListItemText, ShortcutBox } from './styles';
import SelectedSvg from './svg/selected.svg';

export const CustomRangeShortcuts: React.FC<PickersShortcutsProps<DateRange<Date>>> = ({
	items,
	onChange,
	isValid,
	changeImportance = 'accept',
}) => {
	const dateShortcutTypeContext = useContext(DateShortcutTypeContext);
	const [selectedId, setSelectedId] = React.useState<string | null>(
		dateShortcutTypeContext?.shortcutTypeValue ?? null,
	);
	if (!items || items.length === 0) {
		return null;
	}

	const resolvedItems = items.map((item) => {
		const newValue = item.getValue({ isValid });

		return {
			id: item.id,
			label: item.label,
			onClick: () => {
				setSelectedId(item.id ?? null);
				dateShortcutTypeContext?.setShortcutTypeValue((item.id as TDateShortcutType) ?? null);
				onChange(newValue, changeImportance, item);
			},
			disabled: !isValid(newValue),
		};
	});

	return (
		<ShortcutBox>
			<ShortcutList>
				{resolvedItems.map((item) => (
					<ShortcutListItemButton
						key={item.label}
						selected={item.id === selectedId}
						onClick={item.onClick}
					>
						<ShortcutListItemText primary={item.label} />
						{item.id === selectedId ? <SelectedSvg /> : null}
					</ShortcutListItemButton>
				))}
			</ShortcutList>
		</ShortcutBox>
	);
};
